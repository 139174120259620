<template>
  <div>
    <b-row
      v-for="c in organizationContacts"
      :key="c.id"
    >
      <b-col md="12">
        <user-card
          :name="c.user.name"
          :user-id="c.user.id"
          role="Contacto"
          variant="success"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UserCard from './UserCard.vue'

import organizationContacts from '../../services/organizationContacts'

export default {
  components: {
    BRow,
    BCol,
    UserCard,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      organizationContacts: [],
      loading: false,
    }
  },
  async mounted() {
    await this.fetchOrganizationContacts()
  },
  methods: {
    async fetchOrganizationContacts() {
      this.loading = true
      try {
        this.organizationContacts = await organizationContacts.getAllByOrganizationId(this.organizationId)
      } catch (err) {
        this.showNotification('Error al obtener contactos de la organización', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.loading = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
