<template>
  <div>
    <b-card>
      <h4>Estado de Acreditación</h4>
      <b-form-rating
        no-border
        :readonly="true"
        stars="7"
        inline
        variant="warning"
        :value="currentAccreditation.stars"
      />
      <div
        v-show="currentAccreditation.accreditationDate"
        class="ml-1"
      >
        Acreditado: {{ currentAccreditation.accreditationDate }}
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormRating,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormRating,
  },
  props: {
    currentAccreditation: {
      type: Object,
      default: () => ({
        stars: 0,
        accreditationDate: '',
      }),
    },
  },
}
</script>
