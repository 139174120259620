<template>
  <div>
    <b-card>
      <h4>
        {{ name }}
        <b-badge
          :variant="variant"
          class="ml-1"
        >
          {{ role }}
        </b-badge>
      </h4>
      <div v-if="user!=null">
        <div class="text-muted mt-1">
          <feather-icon
            icon="PhoneIcon"
            size="18"
          />
          <b-link :href="`tel:${user.phone}`">
            {{ user.phone }}
          </b-link>
        </div>
        <div class="text-muted mt-1">
          <feather-icon
            icon="MailIcon"
            size="18"
          />
          <b-link :href="`mailto:${user.email}`">
            {{ user.email }}
          </b-link>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BLink, BBadge,
} from 'bootstrap-vue'
import users from '../../services/users'

export default {
  components: {
    BCard,
    BLink,
    BBadge,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '?',
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      user: null,
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler(value) {
        if (value !== '') {
          this.$rtdbBind('user', users.bindChild(value))
        }
      },
    },
  },
}
</script>
