<template>
  <div id="user-profile">
    <custom-app-breadcrumb
      page-title=""
      :breadcrumb="breadcrumb"
    />
    <organization-header :header-data="header" />
    <b-row v-if="organization!=null">
      <b-col lg="6">
        <accreditation-card
          :current-accreditation="currentAccreditation"
        />
        <user-card
          v-if="organization.advisor.id"
          :name="organization.advisor.name"
          :user-id="advisorUserId"
          role="Asesor"
          variant="warning"
        />
      </b-col>
      <b-col lg="6">
        <contact-card-stack :organization-id="organizationId" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import CustomAppBreadcrumb from '../../layouts/components/CustomAppBreadcrumb.vue'
import UserCard from './UserCard.vue'
import AccreditationCard from './AccreditationCard.vue'
import ContactCardStack from './ContactCardStack.vue'
import OrganizationHeader from './OrganizationHeader.vue'
import organizations from '../../services/organizations'
/* eslint-disable global-require */

export default {
  components: {
    CustomAppBreadcrumb,
    OrganizationHeader,
    UserCard,
    AccreditationCard,
    ContactCardStack,
    BRow,
    BCol,
  },
  data() {
    return {
      organization: null,
      logoUrl: '',
    }
  },
  computed: {
    organizationId() {
      return this.$route.params.id
    },
    organizationName() {
      return (this.organization) ? this.organization.name : ''
    },
    organizationLogoFilename() {
      return (this.organization) ? this.organization.logo : ''
    },
    advisorUserId() {
      return (this.organization && this.organization.advisor && this.organization.advisor.id) ? this.organization.advisor.id : ''
    },
    breadcrumb() {
      return [
        {
          text: 'Instituciones',
          to: { name: 'organizations' },
        },
        {
          text: (this.organization) ? this.organization.name : '',
          active: true,
        },
      ]
    },
    header() {
      return {
        avatar: this.logoUrl,
        username: this.organizationName,
        designation: (this.organization) ? `${this.organization.city}, ${this.organization.country.name}` : '',
        coverImg: require('@/assets/images/profile/profile-bg.jpg'),
        section: 'general-info',
        isInactive: (this.organization) ? this.organization.isInactive : false,
      }
    },
    currentAccreditation() {
      return (this.organization && this.organization.currentAccreditation)
        ? this.organization.currentAccreditation
        : {
          stars: 0,
          accreditationDate: '',
        }
    },
  },
  watch: {
    async organizationLogoFilename(value) {
      if (this.organization) {
        this.logoUrl = await organizations.getLogoURL(this.$route.params.id, value)
      }
    },
  },
  mounted() {
    this.$rtdbBind('organization', organizations.bindChild(this.$route.params.id))
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '~@core/scss/base/pages/page-profile.scss';

// profile-header
#user-profile {
  .profile-header {
    .profile-header-nav {
      .navbar {
        .navbar-toggler {
          border: none;
        }
      }
    }
  }
}
</style>
